/* Approved Colors {
	--fb-color: rgba(146, 46, 46,1); #922e2e
	--aa-color: rgba(0, 97, 175,1); #0061af
	--car-color: rgba(163, 1, 52,1); #a30134
	--cinn-color: rgba(98, 203, 201,1); #62cbc9
	--jam-color: rgba(30, 86, 94,1); #1e565e
	--mca-color: rgba(74, 104, 92,1); #4a685c
	--moes-color: rgba(115, 157, 54,1); #739d36
	--sch-color: rgba(250, 117, 69,1); #fa7545
	--sbc-color: rgba(156, 41, 48,1); #9c2930
} */
/* aa */
.bg-aa,
.bg-intl-aa {
    background-color: rgba(0, 96, 175, 1);
}

.badge-aa,
.badge-intl-aa {
    color: white;
    background-color: rgba(0, 96, 175, 1);
}

.text-aa,
.text-intl-aa {
    color: rgba(0, 96, 175, 1);
}
.border-aa,
.border-intl-aa {
    border-color: rgba(0, 96, 175, 1);
}
.btn-aa,
.btn-intl-aa {
    color: #fff;
    background-color: rgba(0, 96, 175, 1);
    border-color: rgba(0, 96, 175, 1);
}

.btn-aa:hover,
.btn-intl-aa:hover {
    color: #fff;
    border-color: rgba(0, 74, 136, 1);
    background-color: rgba(0, 74, 136, 1);
}

.btn-aa.focus,
.btn-intl-aa:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 74, 136, 0.5);
}

/* car  */
.bg-car,
.bg-intl-car {
    background-color: rgba(163, 2, 52, 1);
}

.badge-car,
.badge-intl-car {
    color: white;
    background-color: rgba(163, 2, 52, 1);
}

.text-car,
.text-intl-car {
    color: rgba(163, 2, 52, 1);
}
.border-car,
.border-intl-car {
    border-color: rgba(163, 2, 52, 1);
}
.btn-car,
.btn-intl-car {
    color: #fff;
    background-color: rgba(163, 2, 52, 1);
    border-color: rgba(163, 2, 52, 1);
}

.btn-car:hover,
.btn-intl-car:hover {
    color: #fff;
    border-color: rgba(135, 17, 57, 1);
    background-color: rgba(135, 17, 57, 1);
}

.btn-car.focus,
.btn-intl-car:focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 17, 57, 0.5);
}

/* cinn */
.bg-cinn,
.bg-intl-cinn {
    background-color: rgba(98, 203, 201, 1);
}

.badge-cinn,
.badge-intl-cinn {
    color: white;
    background-color: rgba(98, 203, 201, 1);
}

.text-cinn,
.text-intl-cinn {
    color: rgba(98, 203, 201, 1);
}
.border-cinn,
.border-intl-cinn {
    border-color: rgba(98, 203, 201, 1);
}
.btn-cinn,
.btn-intl-cinn {
    color: #fff;
    background-color: rgba(98, 203, 201, 1);
    border-color: rgba(98, 203, 201, 1);
}

.btn-cinn:hover,
.btn-intl-cinn:hover {
    color: #fff;
    border-color: rgba(118, 214, 207, 1);
    background-color: rgba(118, 214, 207, 1);
}

.btn-cinn.focus,
.btn-intl-cinn:focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 214, 207, 0.5);
}

/* jam */
.bg-jam,
.bg-intl-jam {
    background-color: rgba(30, 86, 94, 1);
}

.badge-jam,
.badge-intl-jam {
    color: white;
    background-color: rgba(30, 86, 94, 1);
}

.text-jam,
.text-intl-jam {
    color: rgba(30, 86, 94, 1);
}
.border-jam,
.border-intl-jam {
    border-color: rgba(30, 86, 94, 1);
}
.btn-jam,
.btn-intl-jam {
    color: #fff;
    background-color: rgba(30, 86, 94, 1);
    border-color: rgba(30, 86, 94, 1);
}

.btn-jam:hover,
.btn-intl-jam:hover {
    color: #fff;
    border-color: rgba(39, 113, 124, 1);
    background-color: rgba(39, 113, 124, 1);
}

.btn-jam.focus,
.btn-intl-jam:focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 113, 124, 0.5);
}

/* mca */
.bg-mca,
.bg-intl-mca {
    background-color: rgba(74, 104, 92, 1);
}

.badge-mca,
.badge-intl-mca {
    color: white;
    background-color: rgba(74, 104, 92, 1);
}

.text-mca,
.text-intl-mca {
    color: rgba(74, 104, 92, 1);
}
.border-mca,
.border-intl-mca {
    border-color: rgba(74, 104, 92, 1);
}
.btn-mca,
.btn-intl-mca {
    color: #fff;
    background-color: rgba(74, 104, 92, 1);
    border-color: rgba(74, 104, 92, 1);
}

.btn-mca:hover,
.btn-intl-mca:hover {
    color: #fff;
    border-color: rgba(58, 81, 72, 1);
    background-color: rgba(58, 81, 72, 1);
}

.btn-mca.focus,
.btn-intl-mca:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 81, 72, 0.5);
}

/* moes */
.bg-moes,
.bg-intl-moes {
    background-color: rgba(115, 157, 54, 1);
}

.badge-moes,
.badge-intl-moes {
    color: white;
    background-color: rgba(115, 157, 54, 1);
}

.text-moes,
.text-intl-moes {
    color: rgba(115, 157, 54, 1);
}
.border-moes,
.border-intl-moes {
    border-color: rgba(115, 157, 54, 1);
}
.btn-moes,
.btn-intl-moes {
    color: #fff;
    background-color: rgba(115, 157, 54, 1);
    border-color: rgba(115, 157, 54, 1);
}

.btn-moes:hover,
.btn-intl-moes:hover {
    color: #fff;
    border-color: rgba(94, 128, 44, 1);
    background-color: rgba(94, 128, 44, 1);
}

.btn-moes.focus,
.btn-intl-moes:focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 128, 44, 0.5);
}

/* sch */
.bg-sch,
.bg-intl-sch {
    background-color: rgba(250, 117, 69, 1);
}

.badge-sch,
.badge-intl-sch {
    color: white;
    background-color: rgba(250, 117, 69, 1);
}

.text-sch,
.text-intl-sch {
    color: rgba(250, 117, 69, 1);
}
.border-sch,
.border-intl-sch {
    border-color: rgba(250, 117, 69, 1);
}
.btn-sch,
.btn-intl-sch {
    color: #fff;
    background-color: rgba(250, 117, 69, 1);
    border-color: rgba(250, 117, 69, 1);
}

.btn-sch:hover,
.btn-intl-sch:hover {
    color: #fff;
    border-color: rgba(249, 89, 31, 1);
    background-color: rgba(249, 89, 31, 1);
}

.btn-sch.focus,
.btn-intl-sch:focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 89, 31, 0.5);
}

/* sbc */
.bg-sbc,
.bg-intl-sbc {
    background-color: rgba(156, 41, 48, 1);
}

.badge-sbc,
.badge-intl-sbc {
    color: white;
    background-color: rgba(156, 41, 48, 1);
}

.text-sbc,
.text-intl-sbc {
    color: rgba(156, 41, 48, 1);
}
.border-sbc,
.border-intl-sbc {
    border-color: rgba(156, 41, 48, 1);
}
.btn-sbc,
.btn-intl-sbc {
    color: #fff;
    background-color: rgba(156, 41, 48, 1);
    border-color: rgba(156, 41, 48, 1);
}

.btn-sbc:hover,
.btn-intl-sbc:hover {
    color: #fff;
    border-color: rgba(187, 49, 58, 1);
    background-color: rgba(187, 49, 58, 1);
}

.btn-sbc.focus,
.btn-intl-sbc:focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 49, 58, 0.5);
}

/* fb */
.bg-fb {
    background-color: rgba(146, 46, 46, 1);
}
.badge-fb {
    color: white;
    background-color: rgba(146, 46, 46, 1);
}
.text-fb {
    color: rgba(146, 46, 46, 1);
}
.border-fb {
    border-color: rgba(146, 46, 46, 1);
}
.btn-fb {
    color: #fff;
    background-color: rgba(146, 46, 46, 1);
    border-color: rgba(146, 46, 46, 1);
}

.btn-fb:hover {
    color: #fff;
    border-color: rgba(146, 46, 46, 1);
    background-color: rgba(146, 46, 46, 1);
}

.btn-fb.focus,
.btn-fb:focus {
    box-shadow: 0 0 0 0.2rem rgba(146, 46, 46, 0.5);
}

.nav-link,
.cursor-pointer {
    cursor: pointer;
}

.custom-checkbox label {
    background-image: none !important;
}

/* Okta Theming */
.okta-sign-in-header {
    display: none;
}

h2.okta-form-title {
    font-weight: 800 !important;
    color: rgba(146, 46, 46, 1) !important;
    font-size: 1.5rem !important;
}

.btnRequestAccess {
    color: #fff;
    background-color: rgba(146, 46, 46, 1) !important;
    border-color: rgba(146, 46, 46, 1) !important;
}

.btnRequestAccess:hover {
    color: #fff;
    background-color: rgba(107, 34, 34, 1) !important;
    border-color: rgba(146, 46, 46, 1) !important;
}
